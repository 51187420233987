<template>
  <div class="wrapper">
	  评估机构
  </div>
</template>

<script>
export default {
	data() {
		return {
		}
	}
}
</script>

<style lang="scss" scoped></style>